var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var R4=function(a){var b=$CLJS.Xm.l;var c=$CLJS.Qf(a);var d=$CLJS.M.h(c,$CLJS.K4);c=$CLJS.M.h(c,$CLJS.L4);if($CLJS.m($CLJS.m(d)?$CLJS.F.h(c,"currency"):d)){c=$CLJS.F4.g;d=$CLJS.Oi.g(d);var e=$CLJS.r($CLJS.C4);d=e.g?e.g(d):e.call(null,d);c=c.call($CLJS.F4,d);c=new $CLJS.k(null,2,[$CLJS.J4,c,$CLJS.M4,c],null)}else c=new $CLJS.k(null,1,[$CLJS.M4,2],null);b=b.call($CLJS.Xm,$CLJS.G([c,a]));return $CLJS.m($CLJS.N4.g(a))?$CLJS.U.l($CLJS.um.h(b,$CLJS.N4),$CLJS.M4,$CLJS.N4.g(a),$CLJS.G([$CLJS.J4,
$CLJS.N4.g(a)])):b},S4=function(a,b){if(null!=a&&null!=a.Ff)a=a.Ff(a,b);else{var c=S4[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=S4._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("NumberFormatter.format-number-basic",a);}return a},kra=function(a,b){var c=$CLJS.gF(a.formatToParts(b),$CLJS.G([new $CLJS.k(null,1,[$CLJS.dk,!0],null)]));a=$CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Rk.g(d),"exponentMinusSign")},c))?c:function(){var d=$CLJS.Bi(function(f){return $CLJS.ki.h($CLJS.Rk.g(f),
"exponentInteger")},c),e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.qg.l(e,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.Rk,"exponentPlusSign",$CLJS.ll,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.R.h($CLJS.p,$CLJS.rg.h(function(d){var e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.Rk);e=$CLJS.M.h(e,$CLJS.ll);switch(d){case "exponentSeparator":return"e";default:return e}},a))},lra=function(a,b){switch($CLJS.L4.g(b)){case "scientific":return function(c){return kra(a,c)};default:return function(c){return a.format(c)}}},
T4=function(a,b,c,d,e){this.options=a;this.rh=b;this.th=c;this.Ue=d;this.cj=e;this.C=393216;this.K=0},mra=function(a,b){return $CLJS.R.h($CLJS.p,function(){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.be(g,v),y=$CLJS.Qf(x);x=$CLJS.M.h(y,$CLJS.Rk);y=$CLJS.M.h(y,$CLJS.ll);var B=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],
null),null);B=B.g?B.g(x):B.call(null,x);$CLJS.m(B)&&(x=$CLJS.F.h(x,"integer")?b:y,n.add(x));v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);q=$CLJS.Qf(q);var u=$CLJS.M.h(q,$CLJS.Rk);q=$CLJS.M.h(q,$CLJS.ll);if($CLJS.m(function(){var v=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.g?v.g(u):v.call(null,u)}()))return $CLJS.nf($CLJS.F.h(u,"integer")?b:q,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,
null)}($CLJS.gF(a.rh.formatToParts(1),$CLJS.G([$CLJS.dk,!0])))}())},U4=function(a){var b=$CLJS.F.h($CLJS.L4.g(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.$m($CLJS.$F($CLJS.Hb,$CLJS.Sh([$CLJS.hra,$CLJS.$qa,$CLJS.cra,$CLJS.era,$CLJS.jra,$CLJS.K4,$CLJS.dra,$CLJS.fra,$CLJS.ara,$CLJS.bra],[$CLJS.M4.h(a,b),$CLJS.P4.g(a),$CLJS.O4.g(a),$CLJS.gra.g(a),$CLJS.F.h($CLJS.L4.g(a),"scientific")?null:$CLJS.L4.h(a,"decimal"),$CLJS.K4.g(a),!0,$CLJS.J4.h(a,b),$CLJS.F.h($CLJS.L4.g(a),"scientific")?"scientific":
null,$CLJS.Q4.g(a)]))));var c=$CLJS.P4.g(a);var d=$CLJS.K4.g(a);c=$CLJS.m(d)?null==c||$CLJS.F.h(c,"symbol"):d;d=lra(b,a);return new T4(a,b,c,d,$CLJS.P)},V4=function(a,b){return U4(R4(b)).Ff(null,a)},X4=function(a,b){var c=$CLJS.um.l(b,$CLJS.MG,$CLJS.G([$CLJS.L4])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.W4.h?$CLJS.W4.h(a,c):$CLJS.W4.call(null,a,c);b=$CLJS.C($CLJS.ug(function(f){return d>=$CLJS.C(f)},nra));var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return[$CLJS.p.g(function(){var f=
a/e,g=$CLJS.Xm.l($CLJS.G([c,new $CLJS.k(null,2,[$CLJS.J4,1,$CLJS.M4,1],null)]));return $CLJS.W4.h?$CLJS.W4.h(f,g):$CLJS.W4.call(null,f,g)}()),$CLJS.p.g(b)].join("")},ora=function(a,b){var c=R4(b);if($CLJS.m(Y4.g(c)))b=Y4.g(c);else if($CLJS.Gb($CLJS.N4.g(c))&&$CLJS.Gb($CLJS.J4.g(c))&&$CLJS.ki.h($CLJS.L4.g(c),"currency")&&Math.abs(a)<($CLJS.F.h($CLJS.L4.g(c),"percent")?.01:1)){b=$CLJS.U.j;var d=$CLJS.um.h(c,$CLJS.M4);c=$CLJS.Q4.h(c,0);b=U4(b.call($CLJS.U,d,$CLJS.O4,2>c?2:c))}else b=U4(c);return S4(b,
a)};T4.prototype.O=function(a,b){return new T4(this.options,this.rh,this.th,this.Ue,b)};T4.prototype.N=function(){return this.cj};
T4.prototype.Ff=function(a,b){a=this.Ue.g?this.Ue.g(b):this.Ue.call(null,b);var c=$CLJS.ira.g(this.options);$CLJS.m($CLJS.m(c)?$CLJS.ki.h(c,".,"):c)&&(b=$CLJS.C(c),c=$CLJS.Zd(c),a=$CLJS.Mu(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.m(c)?c:"",".",b],null)));$CLJS.m(this.th)&&(b=$CLJS.K4.g(this.options),c=$CLJS.D4(b),a=$CLJS.Mu($CLJS.Mu(a,[$CLJS.ui(b)," "].join(""),c),$CLJS.ui(b),c));return a};
var Y4=new $CLJS.N(null,"number-formatter","number-formatter",929788393),Z4=new $CLJS.N(null,"scale","scale",-230427353),$4=new $CLJS.N(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),pra=new $CLJS.N(null,"scientific","scientific",316285837);var a5=new $CLJS.k(null,1,[$CLJS.P4,"symbol"],null),qra=$CLJS.$m(a5),nra=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[1E12,"T"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E9,"B"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E6,"M"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E3,"k"],null)],null),b5=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Mk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,g){f=$CLJS.Qf(g);return $CLJS.M.h(f,$CLJS.L4)},$CLJS.Oj,e,a,b,c,d)}();b5.o(null,$CLJS.Oj,function(a,b){return X4(a,b)});b5.o(null,"percent",function(a,b){return[$CLJS.p.g(X4(100*a,b)),"%"].join("")});b5.o(null,"currency",function(a,b){b=$CLJS.Xm.l($CLJS.G([b,a5]));var c=U4(b);return 1E3>Math.abs(a)?c.Ff(null,a):mra(c,X4(a,b))});b5.o(null,"scientific",function(a,b){return V4(a,$CLJS.Xm.l($CLJS.G([b,new $CLJS.k(null,2,[$CLJS.M4,1,$CLJS.J4,1],null)])))});
$CLJS.W4=function W4(a,b){b=$CLJS.oG(b);var d=$CLJS.Qf(b);b=$CLJS.M.h(d,$CLJS.MG);var e=$CLJS.M.h(d,$4),f=$CLJS.M.h(d,$CLJS.L4),g=$CLJS.M.h(d,Z4);return $CLJS.m($CLJS.m(g)?!isNaN(g):g)?(b=g*a,f=$CLJS.um.h(d,Z4),W4.h?W4.h(b,f):W4.call(null,b,f)):$CLJS.m(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.g(function(){var l=-a,n=$CLJS.U.j(d,$4,!1);return W4.h?W4.h(l,n):W4.call(null,l,n)}()),")"].join(""):$CLJS.m(b)?b5.h(a,R4($CLJS.um.h(d,$CLJS.MG))):$CLJS.F.h($CLJS.Oi.g(f),pra)?V4(a,d):ora(a,d)};
module.exports={compact_currency_options_js:qra,format_number:$CLJS.W4};