var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.stage.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var jwa=function(a){return $CLJS.Vc($CLJS.Sb(function(b,c){return $CLJS.rh.j(b,c,$CLJS.M.j(b,c,0)+1)},$CLJS.Tc($CLJS.P),a))},I9=function(a,b){return new $CLJS.Yi(function(){try{return $CLJS.F9.h(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.XF($CLJS.Vu);$CLJS.m($CLJS.dG("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.eG("metabase.lib.js.metadata",d,$CLJS.X_("Error parsing %s objects: %s",$CLJS.G([a,$CLJS.RZ(c)])),c):$CLJS.eG("metabase.lib.js.metadata",d,$CLJS.X_(c,$CLJS.G(["Error parsing %s objects: %s",
a,$CLJS.RZ(c)])),null));return null}throw e;}})},kwa=function(a,b){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.be(g,x);$CLJS.J(y,0,null);var B=$CLJS.J(y,1,null),H=function(){var I=B;return null==I?null:$CLJS.r(I)}();$CLJS.m(function(){var I=H;return $CLJS.m(I)?$CLJS.F.h($CLJS.j0.g(H),b):I}())&&n.add(H);x+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),
e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);$CLJS.J(q,0,null);var u=$CLJS.J(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=v;return $CLJS.m(x)?$CLJS.F.h($CLJS.j0.g(v),b):x}()))return $CLJS.nf(v,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.vO.g(a);return null==d?null:$CLJS.r(d)}())},lwa=function(a,b){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=
$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.be(g,x);$CLJS.J(y,0,null);var B=$CLJS.J(y,1,null),H=function(){var I=B;return null==I?null:$CLJS.r(I)}();$CLJS.m(function(){var I=H;return $CLJS.m(I)?$CLJS.F.h($CLJS.j0.g(H),b):I}())&&n.add(H);x+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);$CLJS.J(q,0,null);var u=$CLJS.J(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=
v;return $CLJS.m(x)?$CLJS.F.h($CLJS.j0.g(v),b):x}()))return $CLJS.nf(v,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.B9.g(a);return null==d?null:$CLJS.r(d)}())},J9=function(a,b,c,d){this.Xg=a;this.zj=b;this.gd=c;this.dj=d;this.C=393216;this.K=0},K9=function(a,b){if($CLJS.p0(b))a=b;else{var c=new $CLJS.k(null,6,[$CLJS.E9,I9($CLJS.KQ,b),$CLJS.i0,I9($CLJS.k0,b),$CLJS.vO,I9($CLJS.CQ,b),$CLJS.A9,I9($CLJS.OI,b),$CLJS.B9,I9($CLJS.ZP,b),$CLJS.C9,I9($CLJS.mI,
b)],null),d=$CLJS.XF($CLJS.VD);$CLJS.m($CLJS.dG("metabase.lib.js.metadata",d))&&$CLJS.eG("metabase.lib.js.metadata",d,$CLJS.UB.l($CLJS.G(["Created metadata provider for metadata"])),null);a=new J9(a,b,c,$CLJS.P)}return a},L9=function(a,b){return $CLJS.p_.j($CLJS.p_.j((0,$CLJS.L7)($CLJS.H9($CLJS.hF(a))),$CLJS.SQ,function(c){return $CLJS.Xm.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.gR,$CLJS.P],null),c]))}),$CLJS.tO,function(c){var d=function(){var e=$CLJS.vO.g(c);return $CLJS.m(e)?e:function l(g){return new $CLJS.yf(null,
function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.be(q,x);v.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CQ,y,null],null));x+=1}else{q=!0;break a}return q?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}v=$CLJS.C(n);return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CQ,v,null],null),l($CLJS.zd(n)))}return null}},null,null)}(b)}();return $CLJS.U.j(c,$CLJS.vO,jwa(d))})};$CLJS.h=J9.prototype;
$CLJS.h.O=function(a,b){return new J9(this.Xg,this.zj,this.gd,b)};$CLJS.h.N=function(){return this.dj};$CLJS.h.ej=$CLJS.t;$CLJS.h.nh=function(a,b){a=this.gd;a=null==a?null:$CLJS.B9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.oh=function(a,b){return lwa(this.gd,b)};$CLJS.h.qh=function(a,b){a=this.gd;a=null==a?null:$CLJS.i0.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};
$CLJS.h.mh=function(a,b){return kwa(this.gd,b)};$CLJS.h.lh=function(a,b){a=this.gd;a=null==a?null:$CLJS.vO.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.jh=function(a,b){a=this.gd;a=null==a?null:$CLJS.A9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.ph=function(a,b){a=this.gd;a=null==a?null:$CLJS.C9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};
$CLJS.h.kh=function(){var a=this.gd,b=this.Xg;a=null==a?null:$CLJS.E9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};var M9=function M9(a){switch(arguments.length){case 1:return M9.g(arguments[0]);case 2:return M9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.template_tags",M9);M9.g=function(a){return M9.h(a,$CLJS.P)};M9.h=function(a,b){return $CLJS.ova.l($CLJS.G([$CLJS.qva.l($CLJS.G([a,$CLJS.nva.l($CLJS.G([b]))]))]))};M9.v=2;
var N9=function N9(a){return $CLJS.xf(a)?[$CLJS.qf(a),"/",$CLJS.ui(a)].join(""):$CLJS.oe(a)?$CLJS.Ju(a,N9):$CLJS.ne(a)?$CLJS.rg.h(N9,a):a},O9=function O9(a){switch(arguments.length){case 1:return O9.g(arguments[0]);case 2:return O9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.order_by_clause",O9);O9.g=function(a){return O9.h(a,$CLJS.EJ)};
O9.h=function(a,b){return $CLJS.tva.l($CLJS.G([$CLJS.p9.l($CLJS.G([$CLJS.gF(a,$CLJS.G([$CLJS.dk,!0]))])),$CLJS.Oi.g(b)]))};O9.v=2;var P9=function P9(a){switch(arguments.length){case 2:return P9.h(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.available_binning_strategies",P9);P9.h=function(a,b){return $CLJS.Oe($CLJS.o9.l($CLJS.G([a,b])))};
P9.j=function(a,b,c){return $CLJS.Oe($CLJS.o9.l($CLJS.G([a,b,c])))};P9.v=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.h(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.available_temporal_buckets",Q9);Q9.h=function(a,b){return $CLJS.Oe($CLJS.q9.l($CLJS.G([a,b])))};Q9.j=function(a,b,c){return $CLJS.Oe($CLJS.q9.l($CLJS.G([a,b,c])))};
Q9.v=3;var R9=function R9(a){switch(arguments.length){case 2:return R9.h(arguments[0],arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.query_EQ_",R9);R9.h=function(a,b){return R9.j(a,b,null)};R9.j=function(a,b,c){a=L9(a,c);b=L9(b,c);return $CLJS.F.h(a,b)};R9.v=3;
var S9=function S9(a){switch(arguments.length){case 1:return S9.g(arguments[0]);case 2:return S9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.aggregation_clause",S9);S9.g=function(a){return $CLJS.n9.l($CLJS.G([a]))};S9.h=function(a,b){return $CLJS.n9.l($CLJS.G([a,b]))};S9.v=2;
var T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};$CLJS.Ra("metabase.lib.js.filter_clause",T9);T9.l=function(a,b,c){return $CLJS.R.D($CLJS.Wua,a,b,c)};T9.v=2;T9.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};
var U9=function U9(a){switch(arguments.length){case 2:return U9.h(arguments[0],arguments[1]);case 3:return U9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.expressionable_columns",U9);U9.h=function(a,b){return U9.h(a,b)};U9.j=function(a,b,c){return $CLJS.Oe($CLJS.Oua.l($CLJS.G([a,b,c])))};U9.v=3;
module.exports={binning:function(a){return $CLJS.Eua.l($CLJS.G([a]))},query:function(a,b,c){c=$CLJS.gF(c,$CLJS.G([$CLJS.dk,!0]));c=$CLJS.m($CLJS.Rk.g(c))?c:$CLJS.U.j(c,$CLJS.Rk,$CLJS.tO);c=(0,$CLJS.L7)(c);c=$CLJS.s3.g(c);var d=$CLJS.XF($CLJS.VD);$CLJS.m($CLJS.dG("metabase.lib.js",d))&&$CLJS.eG("metabase.lib.js",d,$CLJS.X_("query map: %s",$CLJS.G([$CLJS.aj.l($CLJS.G([c]))])),null);return $CLJS.wva.l($CLJS.G([K9(a,b),c]))},columns_group_columns:function(a){return $CLJS.Oe($CLJS.Jua.l($CLJS.G([a])))},
join_fields:function(a){return $CLJS.Oe($CLJS.dva.l($CLJS.G([a])))},aggregate:function(a,b,c){return $CLJS.zua.l($CLJS.G([a,b,$CLJS.gF(c,$CLJS.G([$CLJS.dk,!0]))]))},join_conditions:function(a){return $CLJS.Oe($CLJS.cva.l($CLJS.G([a])))},query_EQ_:R9,temporal_bucket:function(a){return $CLJS.Fva.l($CLJS.G([a]))},remove_clause:function(a,b,c){return $CLJS.yva.l($CLJS.G([a,b,$CLJS.p9.l($CLJS.G([$CLJS.gF(c,$CLJS.G([$CLJS.dk,!0]))]))]))},suggested_join_condition:function(a,b,c){return $CLJS.gva.l($CLJS.G([a,
b,c]))},replace_clause:function(a,b,c,d){return $CLJS.zva.l($CLJS.G([a,b,$CLJS.p9.l($CLJS.G([$CLJS.gF(c,$CLJS.G([$CLJS.dk,!0]))])),$CLJS.p9.l($CLJS.G([$CLJS.gF(d,$CLJS.G([$CLJS.dk,!0]))]))]))},drop_stage:function(a){return $CLJS.Bva.l($CLJS.G([a]))},available_temporal_buckets:Q9,with_binning:function(a,b){return $CLJS.Fua.l($CLJS.G([a,b]))},append_stage:function(a){return $CLJS.Ava.l($CLJS.G([a]))},join:function(a,b,c){return $CLJS.Yua.l($CLJS.G([a,b,c]))},aggregations:function(a,b){return $CLJS.Oe($CLJS.Bua.l($CLJS.G([a,
b])))},filterable_column_operators:function(a){return $CLJS.Oe($CLJS.Vua.l($CLJS.G([a])))},with_temporal_bucket:function(a,b){return $CLJS.Gva.l($CLJS.G([a,b]))},with_join_conditions:function(a,b){return $CLJS.jva.l($CLJS.G([a,$CLJS.gF(b,$CLJS.G([$CLJS.dk,!0]))]))},available_metrics:function(a){return $CLJS.Oe($CLJS.mva.l($CLJS.G([a])))},with_fields:function(a,b,c){return $CLJS.Qua.l($CLJS.G([a,b,c]))},join_clause:function(a,b){return $CLJS.Zua.l($CLJS.G([a,b]))},aggregation_clause:S9,recognize_template_tags:function(a){return $CLJS.$m($CLJS.pva.l($CLJS.G([a])))},
external_op:function(a){a=$CLJS.Lua.l($CLJS.G([a]));var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.OJ);var c=$CLJS.M.h(b,$CLJS.Jk);b=$CLJS.M.h(b,$CLJS.fl);return{operator:a,options:$CLJS.$m(c),args:$CLJS.Oe(b)}},with_join_fields:function(a,b){return $CLJS.hva.l($CLJS.G([a,b]))},available_binning_strategies:P9,expression:function(a,b,c,d){return $CLJS.Mua.l($CLJS.G([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.Oi.g(a):a;b="string"===typeof b?$CLJS.Oi.g(b):b;return $CLJS.Eva.l($CLJS.G([a,
b]))},order_bys:function(a,b){return $CLJS.Oe($CLJS.uva.l($CLJS.G([a,b])))},display_info:function(a,b,c){return $CLJS.$m.l($CLJS.Hm.D($CLJS.bG($CLJS.kva.l($CLJS.G([$CLJS.b4(a,b),b,c])),$CLJS.TG),$CLJS.k0,$CLJS.bG,$CLJS.TG),$CLJS.G([$CLJS.em,$CLJS.iG]))},fieldable_columns:function(a,b){return $CLJS.Oe($CLJS.Rua.l($CLJS.G([a,b])))},template_tags:M9,selected_aggregation_operators:function(a,b){return $CLJS.Oe($CLJS.Dua.l($CLJS.G([$CLJS.A(a),b])))},order_by_clause:O9,filterable_columns:function(a,b){return $CLJS.Oe($CLJS.Uua.l($CLJS.G([a,
b])))},join_condition_rhs_columns:function(a,b,c,d){return $CLJS.Oe($CLJS.bva.l($CLJS.G([a,b,c,d])))},filters:function(a,b){return $CLJS.Oe($CLJS.Tua.l($CLJS.G([a,b])))},join_condition_lhs_columns:function(a,b,c){return $CLJS.Oe($CLJS.$ua.l($CLJS.G([a,b,c])))},orderable_columns:function(a,b){return $CLJS.Oe($CLJS.vva.l($CLJS.G([a,b])))},suggestedName:function(a){return $CLJS.lva.l($CLJS.G([a]))},metadataProvider:K9,filter_clause:T9,fields:function(a,b){return $CLJS.Oe($CLJS.Pua.l($CLJS.G([a,b])))},
available_aggregation_operators:function(a,b){return $CLJS.Oe($CLJS.Cua.l($CLJS.G([a,b])))},breakouts:function(a,b){return $CLJS.Oe($CLJS.Iua.l($CLJS.G([a,b])))},group_columns:function(a){return $CLJS.Oe($CLJS.Kua.l($CLJS.G([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.Oi.g(b):b;return $CLJS.Cva.l($CLJS.G([a,b]))},joins:function(a,b){return $CLJS.Oe($CLJS.fva.l($CLJS.G([a,b])))},available_join_strategies:function(a,b){return $CLJS.Oe($CLJS.rg.h($CLJS.iG,$CLJS.Xua.l($CLJS.G([a,
b]))))},legacy_query:function(a){return $CLJS.$m.l(N9($CLJS.j3.g(a)),$CLJS.G([$CLJS.em,$CLJS.iG]))},join_strategy:function(a){return $CLJS.iG($CLJS.eva.l($CLJS.G([a])))},aggregation_operator_columns:function(a){return $CLJS.Oe($CLJS.Aua.l($CLJS.G([a])))},join_condition_operators:function(a,b,c,d){return $CLJS.Oe($CLJS.ava.l($CLJS.G([a,b,c,d])))},expressionable_columns:U9,filter:function(a,b,c){return $CLJS.Sua.l($CLJS.G([a,b,$CLJS.gF(c,$CLJS.G([$CLJS.dk,!0]))]))},change_direction:function(a,b){return $CLJS.rva.l($CLJS.G([a,
b]))},expressions:function(a,b){return $CLJS.Oe($CLJS.Nua.l($CLJS.G([a,b])))},with_join_strategy:function(a,b){return $CLJS.iva.l($CLJS.G([a,$CLJS.Oi.g(b)]))},order_by:function(a,b,c,d){return $CLJS.sva.l($CLJS.G([a,b,c,$CLJS.Oi.g(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.Oi.g(a):a;b="string"===typeof b?$CLJS.Oi.g(b):b;return $CLJS.Dva.l($CLJS.G([a,b]))},breakout:function(a,b,c){return $CLJS.Gua.l($CLJS.G([a,b,$CLJS.xva.l($CLJS.G([c]))]))},breakoutable_columns:function(a,
b){return $CLJS.Oe($CLJS.Hua.l($CLJS.G([a,b])))}};