var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vW,wW,Tla,yW,Ula,Vla,Wla,zW,xW;$CLJS.uW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};vW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.NH(b,$CLJS.NI)?$CLJS.IV:$CLJS.NH(b,$CLJS.WK)?$CLJS.DV:$CLJS.NH(b,$CLJS.RJ)?$CLJS.GV:null;return $CLJS.m(b)?$CLJS.mV(b,a):!0};
wW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.NH($CLJS.AL(d),$CLJS.kJ)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.AL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(vW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
Tla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Kj,new $CLJS.k(null,1,[$CLJS.dv,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.vL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,xW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.GL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,xW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.Wu,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.ll);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(wW(b))].join("")}],null),$CLJS.$f(wW)],null)],null)};
yW=function(a){var b=$CLJS.T,c=Tla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.dv,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.vL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vt,new $CLJS.k(null,1,[$CLJS.mk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.xk,c,a],null)};Ula=function(a){return $CLJS.Ve($CLJS.jV,$CLJS.rg.h(function(b){var c=$CLJS.AL(b),d=$CLJS.NH(c,$CLJS.JL);b=d?$CLJS.mV($CLJS.AV,b):d;return $CLJS.m(b)?$CLJS.wl:c},a))};Vla=function(a){a=$CLJS.uW(function(b){return!$CLJS.NH(b,$CLJS.kJ)},$CLJS.rg.h($CLJS.AL,a));return $CLJS.NH(a,$CLJS.JL)?$CLJS.Dl:a};Wla=function(a){return $CLJS.m($CLJS.Xf(function(b){return $CLJS.NH($CLJS.AL(b),$CLJS.kJ)},a))?Vla(a):Ula(a)};
zW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.AW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);xW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.iL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.GL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,xW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.dv,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.AL(a);return $CLJS.Wf(function(d){return vW(d,c)},b)}],null)],null));
$CLJS.iL(zW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vt,new $CLJS.k(null,1,[$CLJS.mk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)],null));$CLJS.rV($CLJS.cm,yW($CLJS.cm));$CLJS.rV($CLJS.wu,yW($CLJS.wu));$CLJS.xV($CLJS.dm,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fl,zW],null)]));$CLJS.xV($CLJS.OK,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fl,zW],null)]));
for(var BW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cm,$CLJS.wu,$CLJS.dm],null)),CW=null,DW=0,EW=0;;)if(EW<DW){var Xla=CW.$(null,EW);$CLJS.kV(Xla,$CLJS.AW);EW+=1}else{var FW=$CLJS.A(BW);if(FW){var GW=FW;if($CLJS.re(GW)){var HW=$CLJS.$c(GW),Yla=$CLJS.ad(GW),Zla=HW,$la=$CLJS.E(HW);BW=Yla;CW=Zla;DW=$la}else{var ama=$CLJS.C(GW);$CLJS.kV(ama,$CLJS.AW);BW=$CLJS.D(GW);CW=null;DW=0}EW=0}else break}$CLJS.zL.o(null,$CLJS.AW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return Wla(a)});
$CLJS.vV($CLJS.sI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));$CLJS.kV($CLJS.sI,$CLJS.ML);
for(var IW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QK,$CLJS.YH,$CLJS.nJ],null)),JW=null,KW=0,LW=0;;)if(LW<KW){var bma=JW.$(null,LW);$CLJS.vV(bma,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));LW+=1}else{var MW=$CLJS.A(IW);if(MW){var NW=MW;if($CLJS.re(NW)){var OW=$CLJS.$c(NW),cma=$CLJS.ad(NW),dma=OW,ema=$CLJS.E(OW);IW=cma;JW=dma;KW=ema}else{var fma=$CLJS.C(NW);$CLJS.vV(fma,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));IW=$CLJS.D(NW);JW=null;KW=0}LW=0}else break}
for(var PW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iI,$CLJS.FJ,$CLJS.UJ],null)),QW=null,RW=0,SW=0;;)if(SW<RW){var gma=QW.$(null,SW);$CLJS.vV(gma,$CLJS.G([$CLJS.wu,$CLJS.dl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));SW+=1}else{var TW=$CLJS.A(PW);if(TW){var UW=TW;if($CLJS.re(UW)){var VW=$CLJS.$c(UW),hma=$CLJS.ad(UW),ima=VW,jma=$CLJS.E(VW);PW=hma;QW=ima;RW=jma}else{var kma=$CLJS.C(UW);$CLJS.vV(kma,$CLJS.G([$CLJS.wu,$CLJS.dl,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));PW=$CLJS.D(UW);QW=null;RW=0}SW=0}else break}$CLJS.vV($CLJS.lI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)]));
$CLJS.zL.o(null,$CLJS.lI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.NH($CLJS.AL(b),$CLJS.dl)&&$CLJS.NH($CLJS.AL(a),$CLJS.dl)?$CLJS.dl:$CLJS.JK});