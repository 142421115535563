var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var X7,Y7,qta,a8,rta,d8,sta,tta,uta,vta,wta,xta,yta,i8,zta,k8,l8,Ata,f8,e8,m8,n8;X7=function(a,b){return null==b||$CLJS.Ie(a,$CLJS.ik)?a:$CLJS.U.j(a,$CLJS.ik,b)};
Y7=function(a){return function(b){var c=$CLJS.ig($CLJS.oi);return function(){function d(l,n){var q=a.g?a.g(n):a.call(null,n);if($CLJS.Ie($CLJS.r(c),q))return l;c.Re(null,$CLJS.kf.h(c.$b(null),q));return b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.A?b.A():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);
};g.A=f;g.g=e;g.h=d;return g}()}};$CLJS.Z7=function(a,b){return $CLJS.F1.h(a,b instanceof $CLJS.N?b:$CLJS.Uj.g(b))};$CLJS.$7=function(a){a=$CLJS.G1.g(a);return $CLJS.m(a)?new $CLJS.k(null,2,[$CLJS.uz,$CLJS.FV,$CLJS.Uj,a],null):null};qta=function(a,b,c){return $CLJS.oh.j($CLJS.Lg,$CLJS.UG(function(d){return $CLJS.M5(a,b,d,new $CLJS.k(null,2,[$CLJS.r1,c,$CLJS.m1,!1],null))}),$CLJS.TO.g($CLJS.U0(a,b)))};
a8=function(a,b,c){return $CLJS.oh.j($CLJS.Lg,$CLJS.UG(function(d){return $CLJS.rg.h($CLJS.yz($CLJS.Y3,d,c),$CLJS.Z0(a,b,d))}),$CLJS.TO.g($CLJS.U0(a,b)))};$CLJS.b8=function(a,b){return $CLJS.g5.h(a,$CLJS.Ie(b,$CLJS.y4)?$CLJS.y4.g(b):b)};$CLJS.c8=function(a,b){var c=$CLJS.Rf($CLJS.EP.g($CLJS.U0(a,b)));return null==c?null:$CLJS.Gm.h(function(d){return $CLJS.U.j($CLJS.Z0(a,b,d),$CLJS.f0,$CLJS.E0)},c)};
rta=function(a,b,c){var d=$CLJS.$_(c);return $CLJS.U.l($CLJS.Z0(a,b,c),$CLJS.f0,$CLJS.w0,$CLJS.G([$CLJS.V,d,$CLJS.SI,d]))};d8=function(a,b){var c=$CLJS.O5(b);if($CLJS.m(c))a=c;else a:{var d=$CLJS.Qf(b);c=$CLJS.M.h(d,$CLJS.hR);d=$CLJS.M.h(d,$CLJS.j0);if($CLJS.m($CLJS.m(c)?d:c)&&(d=$CLJS.Y5(a,d),$CLJS.m(d))){d=$CLJS.V.g(d);a=$CLJS.V.g($CLJS.r0(a,c));a=$CLJS.xa.j?$CLJS.xa.j("%s__via__%s",d,a):$CLJS.xa.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.m(a)?$CLJS.X3(a,$CLJS.V.g(b)):$CLJS.V.g(b)};
$CLJS.b4=function(a,b){b=$CLJS.L5(a,b);return $CLJS.m(b)?$CLJS.V0(a,b,$CLJS.U,$CLJS.G([$CLJS.T5,e8?e8(a,b):f8.call(null,a,b)])):a};
sta=function(a,b){a=$CLJS.U0(a,b);a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.uz),d=$CLJS.M.h(a,$CLJS.wZ);b=$CLJS.T5.g(a);if($CLJS.m(b))return b;var e=$CLJS.nZ.g(a);if($CLJS.m(e)&&$CLJS.m(function(){var g=$CLJS.F.h(c,$CLJS.HZ);return g?g:d}())){var f=function(){var g=c instanceof $CLJS.N?c.S:null;switch(g){case "mbql.stage/native":return $CLJS.D0;case "mbql.stage/mbql":return $CLJS.B0;default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}();return $CLJS.Rf(function(){return function n(l){return new $CLJS.yf(null,
function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.Xm.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.s0,$CLJS.V.g(B),$CLJS.z0,$CLJS.V.g(B)],null),B,new $CLJS.k(null,1,[$CLJS.f0,f],null)]));x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.Xm.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.s0,$CLJS.V.g(x),$CLJS.z0,$CLJS.V.g(x)],
null),x,new $CLJS.k(null,1,[$CLJS.f0,f],null)])),n($CLJS.zd(q)))}return null}},null,null)}($CLJS.c0.g(e))}())}return null};
tta=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q,B=$CLJS.U,H=B.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.f0,$CLJS.E0,$CLJS.G([$CLJS.s0,Q,$CLJS.z0,x]));y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=
$CLJS.C(g);return $CLJS.nf($CLJS.U.l(u,$CLJS.f0,$CLJS.E0,$CLJS.G([$CLJS.s0,$CLJS.V.g(u),$CLJS.z0,function(){var v=$CLJS.V.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.zd(g)))}return null}},null,null)}($CLJS.c8(a,b))}())};
uta=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q,B=$CLJS.U,H=B.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.f0,$CLJS.F0,$CLJS.G([$CLJS.s0,Q,$CLJS.z0,x]));y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=
$CLJS.C(g);return $CLJS.nf($CLJS.U.l(u,$CLJS.f0,$CLJS.F0,$CLJS.G([$CLJS.s0,$CLJS.V.g(u),$CLJS.z0,function(){var v=$CLJS.V.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.zd(g)))}return null}},null,null)}($CLJS.g8.h(a,b))}())};
vta=function(a,b,c){var d=$CLJS.U0(a,b);if($CLJS.m(d)){d=$CLJS.Qf(d);var e=$CLJS.M.h(d,$CLJS.vO);return $CLJS.Rf(function(){return function l(g){return new $CLJS.yf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);return function(){for(var I=0;;)if(I<u){var Q=$CLJS.be(q,I),Y=$CLJS.J(Q,0,null),aa=function(){var qa=Y;qa=qa instanceof $CLJS.N?qa.S:null;switch(qa){case "field":return $CLJS.C0;case "expression":return $CLJS.w0;default:throw Error(["No matching clause: ",
$CLJS.p.g(qa)].join(""));}}(),ha=$CLJS.Z0(a,b,Q);$CLJS.Ff(v,$CLJS.U.l(ha,$CLJS.f0,aa,$CLJS.G([$CLJS.s0,$CLJS.x1.j(a,b,ha),$CLJS.z0,function(){var qa=d8(a,ha);return c.g?c.g(qa):c.call(null,qa)}()])));I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}var x=$CLJS.C(n),y=$CLJS.J(x,0,null),B=function(){var I=y;I=I instanceof $CLJS.N?I.S:null;switch(I){case "field":return $CLJS.C0;case "expression":return $CLJS.w0;default:throw Error(["No matching clause: ",$CLJS.p.g(I)].join(""));
}}(),H=$CLJS.Z0(a,b,x);return $CLJS.nf($CLJS.U.l(H,$CLJS.f0,B,$CLJS.G([$CLJS.s0,$CLJS.x1.j(a,b,H),$CLJS.z0,function(){var I=d8(a,H);return c.g?c.g(I):c.call(null,I)}()])),l($CLJS.zd(n)))}return null}},null,null)}(e)}())}return null};wta=function(a,b,c){return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.UG(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.S(null,2,5,$CLJS.T,[tta,uta],null)))};
xta=function(a,b,c){var d=$CLJS.L5(a,b);return $CLJS.m(d)?$CLJS.Rf(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);return function(){for(var y=0;;)if(y<q){var B=$CLJS.be(n,y);var H=$CLJS.Em.h($CLJS.z0,$CLJS.s0)(B);H=$CLJS.m(H)?H:$CLJS.x1.j(a,b,B);B=$CLJS.um.h($CLJS.U.l(B,$CLJS.f0,$CLJS.t0,$CLJS.G([$CLJS.s0,H,$CLJS.z0,c.g?c.g(H):c.call(null,H)])),$CLJS.V5);u.add(B);y+=1}else return!0}()?
$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}var v=$CLJS.C(l),x=function(){var y=$CLJS.Em.h($CLJS.z0,$CLJS.s0)(v);return $CLJS.m(y)?y:$CLJS.x1.j(a,b,v)}();return $CLJS.nf($CLJS.um.h($CLJS.U.l(v,$CLJS.f0,$CLJS.t0,$CLJS.G([$CLJS.s0,x,$CLJS.z0,c.g?c.g(x):c.call(null,x)])),$CLJS.V5),g($CLJS.zd(l)))}return null}},null,null)}(e8?e8(a,d):f8.call(null,a,d))}()):null};
yta=function(a,b,c,d){return $CLJS.m(c)?(c=$CLJS.X0(a,c),$CLJS.m(c)?$CLJS.Rf($CLJS.Gm.h(function(e){return $CLJS.um.h(e,$CLJS.a4)},$CLJS.M5(a,b,c,new $CLJS.k(null,2,[$CLJS.r1,d,$CLJS.m1,!1],null)))):null):null};
i8=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q;var B=$CLJS.CG.g(x);var H=$CLJS.U.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=X7(H.call($CLJS.U,I,$CLJS.f0,$CLJS.w0,$CLJS.G([$CLJS.s0,Q,$CLJS.z0,x])),$CLJS.m(B)?B:$CLJS.il);y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),
f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=$CLJS.C(g);return $CLJS.nf(function(){var v=$CLJS.CG.g(u),x=$CLJS.U.l,y=u,B=$CLJS.V.g(u);var H=$CLJS.V.g(u);H=c.g?c.g(H):c.call(null,H);return X7(x.call($CLJS.U,y,$CLJS.f0,$CLJS.w0,$CLJS.G([$CLJS.s0,B,$CLJS.z0,H])),$CLJS.m(v)?v:$CLJS.il)}(),f($CLJS.zd(g)))}return null}},null,null)}(h8.h(a,b))}())};
zta=function(a,b,c,d){var e=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.j0),c);return $CLJS.oh.j($CLJS.Lg,$CLJS.Cm.l($CLJS.tg($CLJS.a4),Y7($CLJS.a4),$CLJS.rg.g(function(f){var g=$CLJS.Qf(f);f=$CLJS.M.h(g,$CLJS.Ck);g=$CLJS.M.h(g,$CLJS.a4);return $CLJS.U.j($CLJS.r0(a,g),j8,f)}),$CLJS.G([$CLJS.Fm.g(function(f){return $CLJS.Ie(e,$CLJS.j0.g(f))}),Y7($CLJS.j0),$CLJS.UG(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.j0),l=$CLJS.M.h(f,j8);f=$CLJS.W0(a,g);return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=
$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var Q=0;;)if(Q<y){var Y=$CLJS.be(x,Q),aa=$CLJS.U.l(Y,$CLJS.hR,l,$CLJS.G([$CLJS.f0,$CLJS.x0,$CLJS.s0,$CLJS.V.g(Y)]));Y=B;var ha=$CLJS.U,qa=ha.j,Ea=aa;aa=d8(a,aa);aa=d.g?d.g(aa):d.call(null,aa);ha=qa.call(ha,Ea,$CLJS.z0,aa);Y.add(ha);Q+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v),I=$CLJS.U.l(H,$CLJS.hR,l,$CLJS.G([$CLJS.f0,$CLJS.x0,$CLJS.s0,$CLJS.V.g(H)]));
return $CLJS.nf($CLJS.U.j(I,$CLJS.z0,function(){var Q=d8(a,I);return d.g?d.g(Q):d.call(null,Q)}()),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.C1.D(a,b,f,new $CLJS.k(null,2,[$CLJS.r1,d,$CLJS.m1,!1],null)))})])),c)};
k8=function(a,b,c){var d=$CLJS.Qf(c),e=$CLJS.M.h(d,$CLJS.r1);c=xta(a,b,e);if($CLJS.m(c))return c;c=$CLJS.U0(a,b);c=$CLJS.Qf(c);var f=$CLJS.M.h(c,$CLJS.XP),g=$CLJS.M.h(c,$CLJS.wZ),l=$CLJS.m(f)?function(){var n=$CLJS.W0(a,f);return $CLJS.M5(a,b,n,d)}():null;if($CLJS.m(l))return l;g=$CLJS.m(g)?yta(a,b,g,e):null;return $CLJS.m(g)?g:function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var I=0;;)if(I<
y){var Q=$CLJS.be(x,I),Y=B,aa=$CLJS.U,ha=aa.l,qa=Q,Ea=$CLJS.V.g(Q);Q=$CLJS.V.g(Q);Q=e.g?e.g(Q):e.call(null,Q);aa=ha.call(aa,qa,$CLJS.f0,$CLJS.D0,$CLJS.G([$CLJS.s0,Ea,$CLJS.z0,Q]));Y.add(aa);I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v);return $CLJS.nf($CLJS.U.l(H,$CLJS.f0,$CLJS.D0,$CLJS.G([$CLJS.s0,$CLJS.V.g(H),$CLJS.z0,function(){var I=$CLJS.V.g(H);return e.g?e.g(I):e.call(null,I)}()])),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.c0.g($CLJS.nZ.g(c)))};
l8=function(a,b){var c=$CLJS.J(a,0,null);$CLJS.J(a,1,null);var d=$CLJS.J(a,2,null);switch(c instanceof $CLJS.N?c.S:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.h(d,$CLJS.Ck.g(b));throw $CLJS.zj("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.hS,a,$CLJS.wk,b],null));case "expression":return $CLJS.F.h(d,$CLJS.V.g(b));default:throw $CLJS.zj("unknown clause in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.hS,a,$CLJS.wk,b],null));}};
Ata=function(a,b,c){var d=$CLJS.EP.g($CLJS.U0(a,b));return $CLJS.m(d)?function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){var n=l;if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);return function(){for(var y=0;;)if(y<u){var B=$CLJS.be(q,y);$CLJS.Ff(v,function(){var H=$CLJS.uW(function(Y,aa){return function(ha){return l8(ha,aa)}}(y,B,q,u,v,n,l,d,d),d);if($CLJS.m(H)){var I=$CLJS.P5(H);H=$CLJS.$7(H);H=$CLJS.Qf(H);H=$CLJS.M.h(H,$CLJS.Uj);var Q=B;I=$CLJS.m(I)?$CLJS.b8(Q,
I):Q;return $CLJS.m(H)?$CLJS.Z7(I,H):I}return B}());y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(v),g($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}var x=$CLJS.C(n);return $CLJS.nf(function(){var y=$CLJS.uW(function(I){return function(Q){return l8(Q,I)}}(x,n,l,d,d),d);if($CLJS.m(y)){var B=$CLJS.P5(y);y=$CLJS.$7(y);y=$CLJS.Qf(y);y=$CLJS.M.h(y,$CLJS.Uj);var H=x;B=$CLJS.m(B)?$CLJS.b8(H,B):H;return $CLJS.m(y)?$CLJS.Z7(B,y):B}return x}(),g($CLJS.zd(n)))}return null}},null,null)}(c):c};
f8=function(a){switch(arguments.length){case 2:return e8(arguments[0],arguments[1]);case 3:return m8(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};e8=function(a,b){return m8(a,b,$CLJS.b3())};
m8=function(a,b,c){var d=sta(a,b);if($CLJS.m(d))return d;a=$CLJS.b4(a,b);d=wta(a,b,c);var e=vta(a,b,c);return $CLJS.m(d)?$CLJS.oh.h(d,e):$CLJS.m(e)?($CLJS.Ci(e),$CLJS.oh.j($CLJS.Lg,Y7(function(f){return $CLJS.um.l(f,$CLJS.G0,$CLJS.G([$CLJS.f0,$CLJS.D1,$CLJS.z0]))}),$CLJS.qg.h(e,a8(a,b,c)))):$CLJS.qg.l(k8(a,b,new $CLJS.k(null,2,[$CLJS.m1,!1,$CLJS.r1,c],null)),i8(a,b,c),$CLJS.G([a8(a,b,c)]))};
n8=function n8(a){switch(arguments.length){case 2:return n8.h(arguments[0],arguments[1]);case 3:return n8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};n8.h=function(a,b){return n8.j(a,-1,b)};n8.j=function(a,b,c){return $CLJS.y1.j(a,b,$CLJS.Oi.g(c))};n8.v=3;
$CLJS.g8=function g8(a){switch(arguments.length){case 1:return g8.g(arguments[0]);case 2:return g8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.g8.g=function(a){return $CLJS.g8.h(a,-1)};
$CLJS.g8.h=function(a,b){var c=$CLJS.Rf($CLJS.LP.g($CLJS.U0(a,b)));return null==c?null:$CLJS.oh.j($CLJS.Lg,$CLJS.rg.g(function(d){var e=$CLJS.Z0(a,b,d),f=$CLJS.U.l,g=$CLJS.CG.g(e);return f.call($CLJS.U,X7(e,$CLJS.m(g)?g:$CLJS.il),$CLJS.f0,$CLJS.F0,$CLJS.G([$CLJS.D1,$CLJS.HG.g($CLJS.Zd(d))]))}),c)};$CLJS.g8.v=2;
var h8=function h8(a){switch(arguments.length){case 1:return h8.g(arguments[0]);case 2:return h8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};h8.g=function(a){return h8.h(a,-1)};h8.h=function(a,b){var c=$CLJS.Rf($CLJS.NQ.g($CLJS.U0(a,b)));return null==c?null:$CLJS.Gm.h($CLJS.yz(rta,a,b),c)};h8.v=2;
var j8=new $CLJS.N("metabase.lib.stage","source-field-id","metabase.lib.stage/source-field-id",-962431705),o8=new $CLJS.N("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Cqa={};$CLJS.kV($CLJS.BZ,o8);$CLJS.kV($CLJS.HZ,o8);$CLJS.R3.o(null,$CLJS.BZ,function(a){return $CLJS.Q3(a,new $CLJS.k(null,2,[$CLJS.LP,$CLJS.bg($CLJS.Gm,$CLJS.R3),$CLJS.MZ,$CLJS.bg($CLJS.Gm,$CLJS.R3)],null))});
$CLJS.C1.o(null,o8,function(a,b,c,d){var e=$CLJS.Qf(d);c=$CLJS.M.h(e,$CLJS.r1);d=$CLJS.M.h(e,$CLJS.m1);a=$CLJS.b4(a,b);e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.r1),g=$CLJS.M.h(e,$CLJS.p1),l=$CLJS.M.h(e,$CLJS.u1);e=$CLJS.qg.l(k8(a,b,e),$CLJS.m(l)?i8(a,b,f):null,$CLJS.G([$CLJS.m(g)?qta(a,b,f):null]));return Ata(a,b,$CLJS.qg.h(e,$CLJS.m(d)?zta(a,b,e,c):null))});$CLJS.Y0.o(null,o8,function(a,b){return e8(a,b)});$CLJS.v1.o(null,$CLJS.HZ,function(){return $CLJS.UH("Native query")});
var Bta=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.XP,$CLJS.wZ,$CLJS.LP,$CLJS.EP,$CLJS.MZ,$CLJS.WR,$CLJS.qS],null);
$CLJS.v1.o(null,$CLJS.BZ,function(a,b,c,d){var e=$CLJS.b4(a,b);a=$CLJS.Rf(function(){return $CLJS.bv.h(", ",$CLJS.Fm.h($CLJS.OF,function(){return function l(g){return new $CLJS.yf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.be(q,x);y=n8.j(e,b,y);v.add(y);x+=1}else{q=!0;break a}return q?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}v=$CLJS.C(n);return $CLJS.nf(n8.j(e,b,v),l($CLJS.zd(n)))}return null}},
null,null)}(Bta)}()))}());if($CLJS.m(a))return a;a=$CLJS.L5(e,b);return $CLJS.m(a)?$CLJS.$0.D(e,a,$CLJS.U0(e,a),d):null});